import { Datepicker } from 'stimulus-datepicker';
import RailsNestedForm from '@stimulus-components/rails-nested-form';

import { application } from '@app/controllers/application';
import { BreakInputController } from '@app/controllers/BreakInput';
import { LegacyModalController } from '@app/controllers/LegacyModal';
import { LineClampController } from '@app/controllers/LineClamp';
import { RemoteModal } from '@app/controllers/RemoteModal';
import { ShiftsController } from '@app/controllers/Shifts';
import { ToggleController } from '@app/controllers/Toggle';
import { ClipboardController } from '@app/controllers/Clipboard';
import { CheckboxSelectController } from '@app/controllers/CheckboxSelect';
import { PlacesAutocompleteController } from '@app/controllers/PlacesAutocomplete';

import { OfferScheduleController } from '@app/controllers/offer_form/OfferSchedule';
import { OfferFormController } from '@app/controllers/offer_form/OfferForm';

application.register('break-input', BreakInputController);
application.register('datepicker', Datepicker);
application.register('legacyModal', LegacyModalController);
application.register('line-clamp', LineClampController);
application.register('nested-form', RailsNestedForm);
application.register('clipboard', ClipboardController);
application.register('shifts', ShiftsController);
application.register('toggle', ToggleController);
application.register('remote-modal', RemoteModal);
application.register('checkbox-select', CheckboxSelectController);
application.register('places-autocomplete', PlacesAutocompleteController);

application.register('offer-schedule', OfferScheduleController);
application.register('offer-form', OfferFormController);
